export const selectedColors = [
  "fuchsia",
  "pink",
  "rose",
  "red",
  "light-blue",
  "blue",
  "indigo",
  "purple",
  "cyan",
  "teal",
  // "emerald",
  "green",
  "lime",
  "yellow",
  // "amber",
  "orange",
  "gray",
  "black"
];

export const grayShades = ["warmGray", "trueGray", "blueGray"];

export const allColors = selectedColors.concat(grayShades);

/**
 * Get random base color string
 */
export const getRandomColor = () => {
  return selectedColors[Math.floor(Math.random() * selectedColors.length)];
};

/**
 * Render color with given shade
 */
export const setColor = (color: string, variant = 500) => {
  if (color === "black") return color;
  else return `${color}-${variant}`;
};
