<template>
  <div @click="onClickDelete">
    <slot></slot>
    <DeleteConfirmationModal
      :visible="deleteConfirmationVisible"
      :header="header"
      :message="message"
      :loading="loading"
      @confirm="onConfirmDelete"
      @close="onCancelDelete"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "vue";
// components
import DeleteConfirmationModal from "@/components/modals/DeleteConfirmationModal.vue";
// store
import useWorkAreaStore from "@/store/useWorkAreaStore";
// other
import { WorkArea } from "@/graphql/types";

export default defineComponent({
  name: "WorkAreaDeleteButton",
  components: {
    DeleteConfirmationModal
  },
  props: {
    workArea: { type: Object as PropType<WorkArea>, required: true }
  },
  emits: {
    deleted: null,
    canceled: null
  },
  setup(props, context) {
    // store
    const { onDeleteWorkArea, onDeleteWorkAreaSuccess, loading, error } = useWorkAreaStore({ allWorkAreas: true });

    /**
     * Confirmation dialoge base
     */

    // confirmation diaglog visibility
    const deleteConfirmationVisible = ref(false);
    const onClickDelete = () => {
      deleteConfirmationVisible.value = true;
    };

    // confirmation dialog message
    const header = computed(() => `Delete ${props.workArea.name}`);
    const message = `Are you really sure you want to delete ${props.workArea.name} and all related shifts and demands? This action can't be undone.`;

    /**
     * Confirmation dialoge actions
     */

    const onConfirmDelete = () => {
      onDeleteWorkArea(props.workArea.id);
    };

    const onCancelDelete = () => {
      deleteConfirmationVisible.value = false;
      context.emit("canceled");
    };

    onDeleteWorkAreaSuccess(() => {
      deleteConfirmationVisible.value = false;
      context.emit("deleted");
      console.info("Work area deleted");
    });

    return {
      header,
      message,
      onClickDelete,
      deleteConfirmationVisible,
      loading,
      error,
      onConfirmDelete,
      onCancelDelete
    };
  }
});
</script>
