
import { defineComponent, ref } from "vue";
import { selectedColors, setColor } from "@/utils/colorHelpers";
import { createRandomPrefixedId } from "@/utils/globalHelpers";

export default defineComponent({
  name: "ColorPicker",
  props: {
    position: {
      type: String,
      required: true
    }
  },
  emits: ["color-selected"],
  setup(props, context) {
    // menu visibility
    const menuVisible = ref(false);
    const onCloseMenu = () => {
      menuVisible.value = false;
    };

    // id for click outside directive
    const menuId = createRandomPrefixedId("colorPicker");

    // select color event
    const onSelectColor = (color: string) => {
      context.emit("color-selected", color);
      menuVisible.value = false;
    };

    return { selectedColors, setColor, onSelectColor, createRandomPrefixedId, onCloseMenu, menuId, menuVisible };
  }
});
