<template>
  <div
    class="relative flex"
    :id="menuId"
    v-click-outside="{
      excludedIds: [menuId],
      handler: onCloseMenu
    }"
  >
    <div @click="menuVisible = !menuVisible" class="cursor-pointer">
      <slot></slot>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div v-if="menuVisible === true" class="absolute">
        <div
          :class="position"
          class="flex justify-center items-center w-24 h-24 bg-white rounded-lg shadow-lg border absolute origin-top-left z-10"
        >
          <div class="grid grid-cols-4 gap-1">
            <ul v-for="(color, index) in selectedColors" :key="index">
              <li
                @click="onSelectColor(color)"
                class="h-4 w-4 text-white border rounded-sm cursor-pointer"
                :class="[
                  `bg-${setColor(color, 300)}`,
                  `border-${setColor(color, 400)}`,
                  `hover:bg-${setColor(color, 400)}`
                ]"
              ></li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { selectedColors, setColor } from "@/utils/colorHelpers";
import { createRandomPrefixedId } from "@/utils/globalHelpers";

export default defineComponent({
  name: "ColorPicker",
  props: {
    position: {
      type: String,
      required: true
    }
  },
  emits: ["color-selected"],
  setup(props, context) {
    // menu visibility
    const menuVisible = ref(false);
    const onCloseMenu = () => {
      menuVisible.value = false;
    };

    // id for click outside directive
    const menuId = createRandomPrefixedId("colorPicker");

    // select color event
    const onSelectColor = (color: string) => {
      context.emit("color-selected", color);
      menuVisible.value = false;
    };

    return { selectedColors, setColor, onSelectColor, createRandomPrefixedId, onCloseMenu, menuId, menuVisible };
  }
});
</script>

<style></style>
