
import { defineComponent, computed } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
import WorkAreaAvatar from "@/features/workAreas/WorkAreaAvatar.vue";
import ColorPicker from "@/components/ColorPicker.vue";
// store
import useWorkAreaStore, { createAbbreviation } from "@/store/useWorkAreaStore";
// other
import useWorkAreaForm from "../useWorkAreaForm";

export default defineComponent({
  name: "WorkAreaDetailsSectionProfileGeneral",
  components: {
    DetailsSection,
    WorkAreaAvatar,
    ColorPicker
  },
  props: {
    workAreaId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // store
    const { workArea, onWorkAreaResult, onUpdateWorkArea, onUpdateWorkAreaSuccess, loading, error } = useWorkAreaStore({
      workAreaId: props.workAreaId
    });

    // set form parameters after each component rendering
    const workAreaName = computed(() => (workArea.value ? workArea.value.name : ""));
    const workAreaAbbreviation = computed(() => (workArea.value ? workArea.value.abbreviation : ""));
    const workAreaColor = computed(() => (workArea.value ? workArea.value.color : ""));

    /**
     * form
     */
    const { formData, nameSchema, workAreaSchema, validate } = useWorkAreaForm({
      name: workAreaName.value,
      abbreviation: workAreaAbbreviation.value,
      color: workAreaColor.value
    });

    // placeholder for abbreviation
    const workAreaAbbreviationPlaceholder = computed(() => {
      if (formData.name) return createAbbreviation(formData.name);
      else createAbbreviation(workAreaName.value);
    });

    // display for profile icon
    const workAreaAvatarText = computed(() => {
      if (formData.abbreviation === "") return workAreaAbbreviationPlaceholder.value;
      else return formData.abbreviation.toUpperCase();
    });

    // set workArea color with color picker
    const setColor = (color: string) => {
      formData.color = color;
    };

    // set form values to original graphql query values
    const resetFormValues = () => {
      formData.name = workAreaName.value;
      formData.abbreviation = workAreaAbbreviation.value;
      formData.color = workAreaColor.value;
    };

    // set values after result is queried
    onWorkAreaResult(() => {
      resetFormValues();
    });

    // check if form values have been updated
    const isFormUnchanged = computed(() => {
      if (
        formData.name === workAreaName.value &&
        (formData.abbreviation === workAreaAbbreviation.value || formData.abbreviation === "") &&
        formData.color === workAreaColor.value
      )
        return true;
      else return false;
    });

    /**
     * api call
     */

    // Validate and create workArea
    const onSubmit = () => {
      // set abbreviation
      formData.abbreviation = workAreaAvatarText.value ? workAreaAvatarText.value : formData.abbreviation;
      // check if abbreviation is set
      workAreaSchema
        .validate(formData)
        .then(() => {
          // execute api call
          onUpdateWorkArea(formData.name, formData.abbreviation, formData.color);
        })
        .catch(() => {
          validate.value = true;
        });
    };

    onUpdateWorkAreaSuccess(() => {
      console.info("workArea updated");
    });

    return {
      formData,
      validate,
      workArea,
      onSubmit,
      nameSchema,
      resetFormValues,
      workAreaAbbreviationPlaceholder,
      workAreaAvatarText,
      isFormUnchanged,
      loading,
      error,
      setColor
    };
  }
});
