import { ref, reactive } from "vue";
import * as yup from "yup";

export interface WorkAreaForm {
  name: string;
  abbreviation: string;
  color: string;
}

/**
 * WorkArea form validation
 * @param initialData Initial data when modifying workArea
 */
export default function useWorkAreaForm<T extends object>(initialData: T) {
  const validate = ref(false);

  /**
   * Base properties
   * Using yup to generate a validation schema
   * https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
   */

  const nameSchema = yup.string().required("Please enter a name");
  const abbreviationSchema = yup.string().required("Please enter an abbreviation");
  const colorSchema = yup.string().required("Please enter a color");

  /**
   * Schemas
   */
  const workAreaSchema = yup.object().shape({
    name: nameSchema,
    abbreviation: abbreviationSchema,
    color: colorSchema
  });

  /**
   * Form data
   */
  // create reactive form state
  const formData = reactive({ ...initialData });

  function resetForm(data = initialData) {
    Object.assign(formData, data);
    validate.value = false;
  }

  return {
    formData,
    resetForm,
    validate,
    workAreaSchema,
    nameSchema,
    abbreviationSchema,
    colorSchema
  };
}
