<template>
  <WorkAreaDetailsPageSettingsGeneral v-if="workAreaId" :workAreaId="workAreaId" />

  <div class="flex w-full justify-center sm:justify-end pb-6">
    <WorkAreaDeleteButton v-if="workArea" :workArea="workArea" @deleted="router.push({ path: '/work-areas' })">
      <BaseButton :variant="'danger'">Delete</BaseButton>
    </WorkAreaDeleteButton>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
// components
import WorkAreaDetailsPageSettingsGeneral from "./WorkAreaDetailsPageSettingsGeneral.vue";
import WorkAreaDeleteButton from "@/features/workAreas/WorkAreaDeleteButton.vue";
// store
import useWorkAreaStore from "@/store/useWorkAreaStore";
// other
import { useRoute, useRouter } from "vue-router";
import { repairUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "WorkAreaDetailsPageSettings",
  components: {
    WorkAreaDetailsPageSettingsGeneral,
    WorkAreaDeleteButton
  },
  setup() {
    // get work area id
    const {
      params: { workAreaId: workAreaUrlId }
    } = useRoute();
    const workAreaId = computed(() => repairUuid(workAreaUrlId));

    // get work area
    const { workArea } = useWorkAreaStore({ workAreaId: workAreaId.value });

    // router for redirect after delete
    const router = useRouter();

    return { workAreaId, workArea, router };
  }
});
</script>
